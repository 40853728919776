import React from "react"
import LayoutProductions from "../../components/layout/layout-productions"
import TitledContentWrapper from "../../components/layout/titled-content-wrapper"
import { ProductionsSubmissionHowTo } from "../../components/productions/productions-submission-how-to"
import { ProductionsSubmissionForm } from "../../components/productions/productions-submission-form"
import { graphql, useStaticQuery } from "gatsby"

const SubmissionPage = () => {
  const data = useStaticQuery(graphql`
    {
      strapi {
        submissionPage {
          SubmissionTitle
          SubmissionDescription
        }
      }
    }
  `)

  return (
    <LayoutProductions>
      <TitledContentWrapper title="submission">
        <ProductionsSubmissionHowTo submission={data.strapi.submissionPage} />
      </TitledContentWrapper>
      <ProductionsSubmissionForm />
    </LayoutProductions>
  )
}

export default SubmissionPage
