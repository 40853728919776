import { Box, Typography, Button, CircularProgress } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import React, { useState } from "react"
import {
  FormControl,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core"

import ReCAPTCHA from "react-google-recaptcha"
import { Link, navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "50px",
    maxWidth: "1000px",
    margin: "auto",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "16px",
    [theme.breakpoints.down("sm")]: {},
  },

  title: {
    marginBottom: "30px",
    textShadow: "0px 15px 30px #000000",
    text: "uppercase",
    fontWeight: "700",
  },
  input: {
    textTransform: "uppercase",
    marginBottom: "20px",
    width: "100%",
  },
  inputText: {
    color: "#F26522",
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "-6px",
  },
  inputTextType: {
    color: "#FFFFFF",
  },
  InputLabel: {
    color: "#F26522",
    fontSize: "14px",
    fontWeight: "700",
    marginTop: "-6px",
  },
  downloadContainer: {
    maxWidth: "500px",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  upload: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "black",
    fontSize: "16px",
    fontWeight: "700",
    border: "solid 3px #F26522",
    background: "#F26522",
    width: "320px",
    height: "50px",
    paddingTop: "11px",
    paddingBottom: "11px",
    cursor: "pointer",
    marginLeft: "0px",
    marginRight: "20px",
    textAlign: "center",
    marginTop: "4px",
    marginBottom: "20px",
    "&:hover": {
      color: "#F26522",
    },
  },
}))

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#343333",
        width: "100%",
        borderRadius: "0",
      },
      "&:hover fieldset": {
        borderColor: "#D5810F",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#F26522",
        fontSize: "14px !important",
        color: "#F26522",
      },
    },
  },
})(TextField)

export const ProductionsSubmissionForm = () => {
  const classes = useStyles()
  const [isPending, setIsPending] = useState(false)
  const [captchaToken, setCaptchaToken] = useState("")

  const handleSubmit = e => {
    e.preventDefault()

    if (captchaToken === "") {
      alert("Please fill Google captcha field!")
      return
    }

    setIsPending(true)

    const request = new XMLHttpRequest()

    const formData = new FormData()

    const formElements = e.target.elements

    const data = {}

    for (let i = 0; i < formElements.length; i++) {
      const currentElement = formElements[i]
      if (!["submit", "file"].includes(currentElement.type)) {
        data[currentElement.name] = currentElement.value
      } else if (currentElement.type === "file") {
        if (currentElement.files.length === 1) {
          const file = currentElement.files[0]
          formData.append(`files.${currentElement.name}`, file, file.name)
        } else {
          for (let i = 0; i < currentElement.files.length; i++) {
            const file = currentElement.files[i]

            formData.append(`files.${currentElement.name}`, file, file.name)
          }
        }
      }
    }

    formData.append("data", JSON.stringify(data))

    request.withCredentials = true

    request.onload = function () {
      navigate("/productions/submission-success")
      setIsPending(false)
    }
    request.open("POST", `${process.env.GATSBY_STRAPI_URL}/submissions`)

    request.send(formData)
  }

  const onCaptchaChange = token => setCaptchaToken(token)

  return (
    <Box className={classes.container}>
      <form onSubmit={handleSubmit} action="/">
        <FormControl style={{ width: "100%" }}>
          <Box style={{ maxWidth: "350px", width: "100%" }}>
            <Typography variant="h3" className={classes.title}>
              CONTACT INFORMATION
            </Typography>
            <CssTextField
              id="firstName"
              label="first name"
              variant="outlined"
              color="first"
              required
              className={classes.input}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              InputProps={{
                className: classes.inputTextType,
              }}
              name="firstName"
            />
            <CssTextField
              id="lastName"
              label="last name"
              required
              variant="outlined"
              color="first"
              className={classes.input}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              InputProps={{
                className: classes.inputTextType,
              }}
              name="lastName"
            />
            <CssTextField
              id="address"
              label="address"
              variant="outlined"
              required
              color="first"
              className={classes.input}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              InputProps={{
                className: classes.inputTextType,
              }}
              name="address"
            />
            <CssTextField
              id="email"
              label="e-mail address"
              variant="outlined"
              required
              color="first"
              className={classes.input}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              InputProps={{
                className: classes.inputTextType,
              }}
              name="email"
              type="email"
            />
          </Box>
          <Box style={{ maxWidth: "750px", width: "100%" }}>
            <Typography
              variant="h3"
              className={classes.title}
              style={{ marginTop: "40px" }}
            >
              about the material
            </Typography>
            <CssTextField
              id="submissionTitle"
              label="title"
              required
              variant="outlined"
              color="first"
              className={classes.input}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              InputProps={{
                className: classes.inputTextType,
              }}
              name="submissionTitle"
            />
            <CssTextField
              id="submissionDescription"
              label="description"
              required
              variant="outlined"
              color="primary"
              multiline
              rows={6}
              className={classes.input}
              InputLabelProps={{
                className: classes.InputLabel,
              }}
              InputProps={{
                className: classes.inputTextType,
              }}
              name="submissionDescription"
            />
          </Box>
          <Box>
            <Typography
              variant="h4"
              className={classes.title}
              style={{ marginTop: "40px" }}
            >
              upload referring material
            </Typography>

            <Box className={classes.downloadContainer}>
              <CssTextField
                id="referringMaterial"
                name="referringMaterial"
                label=""
                type="file"
                variant="outlined"
                color="primary"
                className={classes.input}
                InputLabelProps={{
                  className: classes.InputLabel,
                }}
                inputProps={{
                  className: classes.inputTextType,
                  accept:
                    ".pdf,.doc,.docx,.rtf,.odt,.jpg,.png, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }}
              />
            </Box>
            <Typography variant="h4" className={classes.format}>
              allowed filetypes:{" "}
              <span style={{ color: "#F26522" }}>
                .pdf, .doc, .docx, .rtf, .odt, .jpg, .png
              </span>
            </Typography>
            <Typography
              variant="h3"
              className={classes.title}
              style={{ marginTop: "40px" }}
            >
              upload agreement<span style={{ color: "#F26522" }}>*</span>
            </Typography>
            <Box className={classes.downloadContainer}>
              <CssTextField
                id="agreement"
                name="agreement"
                type="file"
                label=""
                required
                variant="outlined"
                color="primary"
                className={classes.input}
                InputLabelProps={{
                  className: classes.InputLabel,
                }}
                inputProps={{
                  className: classes.inputTextType,
                  accept:
                    ".pdf,.doc,.docx, application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                }}
              />
            </Box>
            <Typography variant="h4" className={classes.format}>
              allowed filetypes:{" "}
              <span style={{ color: "#F26522" }}>.pdf, .doc, .docx</span>
            </Typography>
          </Box>
          <Box
            style={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox name="checkedRef" style={{ color: "#F26522" }} />
              }
              label="I have any kind of material referring to the synopsys."
            />
            <Box
              style={{
                display: "flex",
                marginTop: "-10px",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedPol"
                    required
                    style={{ color: "#F26522" }}
                  />
                }
                label="I agree to Digic Pictures Kft's"
              />
              <Link
                to="/privacy-policy"
                style={{
                  color: "#F26522",
                  fontSize: "20px",
                  marginLeft: "-8px",
                  textDecoration: "none",
                  marginTop: "8px",
                }}
              >
                Privacy Policy
              </Link>
            </Box>
          </Box>
          <ReCAPTCHA
            sitekey="6LeerjEaAAAAACy9slw1CRkV3Qm3dj483xMPc4oo"
            onChange={onCaptchaChange}
          />
          <Box>
            <Button
              className={classes.upload}
              style={{
                width: "320px",
                marginTop: "40px",
              }}
              type="submit"
              disabled={isPending}
            >
              {isPending ? (
                <>
                  please wait... submission in progress
                  <CircularProgress size={20} color="secondary" />
                </>
              ) : (
                `submit idea`
              )}
            </Button>
          </Box>
        </FormControl>
      </form>
    </Box>
  )
}
