import { Box, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import React from "react"
// import { Link } from "gatsby"
import Download from "./download_black"

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "50px",
    maxWidth: "1000px",
    margin: "auto",
    paddingLeft: "16px",
    paddingRight: "16px",
    paddingBottom: "16px",
  },
  title: {
    marginBottom: "20px",
    marginTop: "40px",
    textShadow: "0px 15px 30px #000000",
    text: "uppercase",
    fontWeight: "700",
  },
  content: {
    marginBottom: "20px",
  },
  link: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "#D5810F",
    fontSize: "16px",
    fontWeight: "700",
    "&:hover": {
      color: "#FFF",
    },
  },
  back: {
    textAlign: "left",
    marginBottom: "20px",
  },
  downloadContainer: {
    display: "flex",
    maxWidth: "460px",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  download: {
    textDecoration: "none",
    textTransform: "uppercase",
    color: "black",
    fontSize: "16px",
    fontWeight: "700",
    border: "solid 3px #F26522",
    background: "#F26522",
    width: "200px",
    paddingTop: "11px",
    paddingBottom: "11px",
    cursor: "pointer",
    marginLeft: "0px",
    marginRight: "20px",
    marginTop: "25px",
    textAlign: "center",
  },
}))

export const ProductionsSubmissionHowTo = ({ submission }) => {
  const classes = useStyles()

  return (
    <Box className={classes.container}>
      {/* <Box className={classes.back}>
        <Link to="/" className={classes.link}>
          {"<"}back to home
        </Link>
      </Box> */}
      <Box>
        <Typography variant="h2" className={classes.title}>
          {submission.SubmissionTitle}
        </Typography>
        <Typography variant="body1" className={classes.content}>
          {submission.SubmissionDescription}
        </Typography>
        <Typography variant="body1" style={{ textTransform: "uppercase" }}>
          <span style={{ color: "#F26522" }}>*</span> required fields
        </Typography>
        <Typography variant="h3" className={classes.title}>
          agreement
        </Typography>
        <Typography
          variant="body1"
          style={{ textTransform: "uppercase", fontWeight: "700" }}
        >
          download the agreement here{" "}
          <span style={{ color: "#F26522" }}>*</span>
        </Typography>
        <Box className={classes.downloadContainer}>
          <Box className={classes.download}>
            downloaD (HUN) <Download />
          </Box>
          <Box className={classes.download}>
            downloaD (ENG) <Download />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
