import React from "react"

function Download() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      style={{ marginBottom: "-10px" }}
    >
      <path
        fill="#010000"
        fillRule="evenodd"
        d="M15 9h4l-7 7-7-7h4V3h6v6zM5 20v-2h14v2H5z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

export default Download
